@import './Mixins.scss';
@import './Variables.scss';

.Declaration {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .sidebar-child-scroll {
    max-height: unset;
    padding-right: 60px;
  }
  .button-primary {
    margin: 24px 0 0;
  }
  .productsContainer {
    background-color: $primary-bg-color;
    border-radius: $primary-border-radius;
    margin: 8px 0;
  }
  .addNewProduct {
    background: $primary-bg-color;
    border-radius: $primary-border-radius;
    padding: 16px;
    cursor: pointer;
    transition: $primary-transition;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $third-grey;
    .iconDiv {
      background-color: $color-white;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      svg path {
        fill: $bg-orange;
      }
    }
  }
  .shipperName {
    padding: 0 30px;
    height: 72px;
  }
  .disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .packageProductDivTitle {
    font-family: HelveticaBoldCaps;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'case' on;
    color: $secondary-grey;
    margin: unset;
  }
  .packagePrice {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $third-grey;
    margin-right: 23px;
  }
  .packageTotalPrice {
    background: $primary-bg-color;
    border-radius: $primary-border-radius;
    padding: 12px 24px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: $secondary-grey;
  }
  .BigTotalPrice {
    .packagePrice {
      margin-right: 6px;
    }
    .packageTotalPrice {
      padding: 12px 14px;
    }
  }
}
.declarationBtn {
  padding: 20px 0;
  height: unset;
  .auth-p {
    font-family: HelveticaNeueLTGEO-55Roman;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    font-feature-settings: 'case' on;
    color: $color-white;
  }
}
.ShippersSidebar {
  height: 100%;
  .search-icon path {
    fill: #878e99 !important;
  }
  input {
    color: #a9a9a9;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
  }
  .searchDiv {
    position: relative;
  }
  .searchedItems {
    padding: 0 10px 5px;
    max-height: 40vh;
    overflow: scroll;
    position: absolute;
    z-index: 88;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 99%;
    background-color: $color-white;
    transition: $primary-transition;
    border-radius: $primary-border-radius;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    box-shadow: 0px 6px 10px 0 rgba(182, 180, 178, 0.541);
    .shippersListItem {
      padding: 5px 20px;
      border-radius: $primary-border-radius;
      cursor: pointer;
    }
    .shippersListItem:hover {
      background-color: $primary-bg-color;
    }
    .active {
      background: #fef2e9;
      border: 2px solid #f68424;
      box-sizing: border-box;
      border-radius: $primary-border-radius;
    }
  }
  .shippersListDiv {
    .changableInfo {
      cursor: pointer;
    }
    .active {
      background: #fef2e9;
      border: 2px solid #f68424;
      box-sizing: border-box;
      border-radius: $primary-border-radius;
    }
  }
  .sidebar-child-scroll {
    max-height: 80%;
    height: unset;
    padding-right: 60px;
  }
  .shippersMainDiv {
    max-height: 95%;
  }
}
.SectionTitle {
  font-family: HelveticaBoldCaps;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: 'case' on;
  color: $secondary-grey;
  margin-bottom: 35px;
}
.SectionOption {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $third-grey;
  margin: unset;
}
.SectionSubTitle {
  font-family: HelveticaBoldCaps;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  font-feature-settings: 'case' on;
  color: $secondary-grey;
  margin-top: 18px;
  margin-bottom: 16px;
}
.codeInputWrapper {
  margin-bottom: 24px;
  .searchInput {
    margin-bottom: unset;
  }
}
.searchInput {
  background: $color-white;
  border: 0.5px solid #dddddd;
  box-sizing: border-box;
  border-radius: 60px;
  padding: 20px 24px;
  margin-bottom: 24px;
  .searchIcon {
    height: 16px;
    width: 16px;
    path {
      fill: $third-grey !important;
    }
  }
  input {
    margin-left: 8px;
    padding: 0;
    width: 90%;
    height: 20px;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $secondary-grey;
    font-family: HelveticaNeueLTGEO-55Roman;
  }
  input:placeholder {
    font-family: HelveticaNeueLTGEO-55Roman;
  }
}
.AddProductSidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .errorBorder {
    border: 1px solid red !important;
  }
  .sidebar-child-scroll {
    max-height: 80%;
    padding-right: 60px;
  }
  .productCategories {
    background-color: $primary-bg-color;
    border-radius: $primary-border-radius;
    overflow: scroll;
    max-height: 34vh;
    .productCategory {
      margin-bottom: 11px;
      cursor: pointer;
    }
    .productCategory:last-child {
      margin-bottom: unset;
    }
    .notFound {
      padding: 50px 16px;
      cursor: auto;
      text-align: center;
    }
  }
  .productCategories::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 100%;
  }
  .productCategories::-webkit-scrollbar-thumb {
    border-radius: 4px;
    width: 100%;
    height: 56px;
    background-color: $gray-color;
  }
  .ProductPriceInput {
    padding: 21px 20px 21px 24px;
    width: 100%;
    border-radius: $primary-border-radius;
    border: 0.5px solid #dddddd;
    margin-bottom: 3px;
    input {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      border: none;
      width: 100%;
      color: #3b414d;
    }
    .currency {
      font-family: HelveticaBoldCaps;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: black;
    }
  }
}
.flag-select__btn {
  border: 0;
  padding: 5px 3px;
  border-radius: 5px;
  span {
    display: flex;
    pointer-events: none;
    img{      
      width: 22px;
    } 
    &:last-child {
      margin-left: 5px;    
    }
  }
}
.country-dropdown{
  cursor: pointer;
  border-radius: 5px;
  position: relative; 
  .flag-select{
    &__options {
      z-index: 111;
      min-width: 190px;
      padding: 4px;
      border-radius: 4px;
      position: absolute;
      top: calc(100% + 5px);
      right:0;
      background: #f5f5f5;
      max-height: 220px;
      overflow-y: auto;
      overflow-x: hidden;
      box-shadow: 0 0 10px rgba(0,0,0,.5);     
      li {
        margin: 4px 0; 
      }
      span {
        img {
          margin-right: 7px;
          width: 20px;
        }
      }
    }
  }
  .flag-select__option__label{
    margin-left: 10px;
  }
}
