body {
  background: #fff !important;
  margin: 0;
  overflow-x: hidden;
  color: #5f5f5f;
  font-weight: 300;
}

.div {
  width: 90%;
  margin: 0 auto;
  padding-top: 30px;
}

.invoice-table {
  border-radius: 4px;
}

.invoice-table th,
.invoice-table .tfoot td {
  font-weight: 500;
  text-align: center;
  background: #f6f9f9;
}

.invoice-table td {
  text-align: center;
  white-space: pre;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.m-t-40 {
  margin-top: 40px !important;
}

.font-medium {
  font-weight: 500;
}

.text-align-left {
  text-align: left !important;
}
