$primary-border-radius: 12px;
$btn-border-radius: 32px;
$primary-font-size: 14px;
$main-padding: 60px 150px 110px 150px;
$responsive-margin: 0 auto;
$primary-btn-height: 60px;
$secondary-btn-height: 48px;
$round-circle: 50%;
$primary-transition: all 0.3s ease-in-out;
$primary-bg-color: #f7f6f5;
$color-warning: #f68220;
$color-white: white;
$color-grey: #f5f5f5;
$color-orange: #f68424;
$color-red: #ff4040;
$color-green: #30bf54;
$gray-color: #9da4b3;
$secondary-grey: #3b414d;
$third-grey: #878e99;
$bg-orange: #f68424;
$bg-orange-hover: #db7620;
$bg-danger: #fa3f3f;
$bg-hover-danger: #bb2d3b;
$bg-pink: #ffe5e5;
$bg-grey-hover: #ebe8e6;
$bg-green: #eaf8ed;
$text-grey: #a9a9a9;
$border-grey: #EBE9E7;
$bg-orange-selected: #fef2e9;
