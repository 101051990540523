.popup-width{
  width: 40%
}

@media (max-width: 768px) {
  .popup-width{
    width: 90%
  }
}

.min-width{
  min-width: 120px;
}