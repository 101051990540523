@import './Variables.scss';

@mixin color($color: white) {
  color: $color;
}

@mixin div-bg($bg: white, $padding: 24px) {
  background-color: $bg;
  border-radius: $primary-border-radius;
  padding: $padding;
}

@mixin inProgress {
  background-color: #fef7f2;
  color: #ff9740;
  fill: #ff9740;
  stroke: #ff9740;
}

@mixin returned {
  background-color: #fef5e8;
  color: #f6a119;
  fill: #f6a119;
}

@mixin arrived {
  color: #3b414d;
  background-color: #f7f6f5;
  fill: #f7f6f5;
}

@mixin sent {
  color: #30bf54;
  background-color: #eaf8ed;
  fill: #30bf54;
}

@mixin invoice {
  color: #fa3f3f;
  background-color: #feebeb;
  fill: #fa3f3f;
}

@mixin btn-primary(
  $padding: '',
  $width: 100%,
  $fontSize: '',
  $fontWeight: 700
) {
  color: white;
  background: $color-warning;
  padding: $padding;
  width: $width;
  font-size: $fontSize;
  font-weight: $fontWeight;
  transition: $primary-transition;
  border-radius: $btn-border-radius;
  border: none;
  height: $primary-btn-height;
}

@mixin btn-secondary(
  $padding: '',
  $width: 100%,
  $fontSize: '',
  $fontWeight: 700
) {
  color: $secondary-grey;
  background: $primary-bg-color;
  padding: $padding;
  width: $width;
  font-size: $fontSize;
  font-weight: $fontWeight;
  transition: $primary-transition;
  border-radius: $btn-border-radius;
  border: none;
  height: $primary-btn-height;
}

@mixin btn-danger($padding: '', $width: 100%, $fontSize: '', $fontWeight: 700) {
  color: white;
  background: $bg-danger;
  padding: $padding;
  width: $width;
  font-size: $fontSize;
  font-weight: $fontWeight;
  transition: $primary-transition;
  border-radius: $btn-border-radius;
  border: none;
  height: $primary-btn-height;
}

@mixin btn-warning(
  $padding: '',
  $width: 100%,
  $fontSize: '',
  $fontWeight: 700
) {
  color: $color-warning;
  background: $color-grey;
  padding: $padding;
  width: $width;
  font-size: $fontSize;
  font-weight: $fontWeight;
  transition: $primary-transition;
  border-radius: $btn-border-radius;
  border: none;
  height: $primary-btn-height;
}

@mixin btn-dark($padding: '', $width: 100%, $fontSize: '', $fontWeight: 700) {
  color: white;
  background: rgba(255, 255, 255, 0.0373);
  padding: $padding;
  width: $width;
  font-size: $fontSize;
  font-weight: $fontWeight;
  transition: $primary-transition;
  border-radius: $btn-border-radius;
  border: none;
  height: $primary-btn-height;
}
