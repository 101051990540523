@import './Mixins.scss';
@import './Variables.scss';

body {
  margin: 0 !important;
  padding: 0 !important;
  background-color: $primary-bg-color !important;
  overflow-x: hidden;
  font-family: HelveticaNeueLTGEO-55Roman, Serif;
  touch-action: pan-y, pan-x;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  border: 1px solid #dddddd !important;
  box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-text-fill-color: #a9a9a9;
}

input {
  color: #3b414d;
}

.barcode-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  .barcode-title {
    text-align: center;
    font-size: 24px;
    color: #3b414d;
    margin-bottom: 16px;
  }

  h4 {
    font-weight: 400;
    font-size: 18px;
    color: #3b414d;
    margin: 6px 0;
    margin-bottom: 30px;
  }

  .parent-128 {
    height: 50px;
    margin-bottom: 25px;
  }

  .code128 {
    font-family: 'Libre Barcode 128', cursive;
    font-size: 4rem;
    -webkit-transform: scaleY(1.5);
    -ms-transform: scaleY(1.5);
    transform: scaleY(1.5);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.button p,
.navbar-li p {
  font-family: HelveticaBoldCaps;
}

.btn-disabled {
  background: #f68424 !important;
  opacity: 0.5;
}

.navbar-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 10px;
}

.navbar-li,
p,
a {
  text-decoration: none;
  text-transform: none;
  margin: 0;
}

a {
  color: black;
}

a:hover {
  color: gray;
}

.navbar-li-p {
  color: #878e99;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
  font-weight: 600;
  margin-right: 29px;
  // padding-bottom: 11px;
  padding-bottom: 0;
}

.navbar-li-active {
  color: $secondary-grey;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
  font-weight: 600;
  margin-right: 29px;
  // padding-bottom: 11px;
  padding-bottom: 0;
}

.navbar-li-p:hover {
  color: black;
}

.FlagImage {
  width: 20px;
  height: 14px;
}

.main-img {
  width: 100%;
  height: auto;
}

.border-rad-12 {
  border-radius: $primary-border-radius;
}

.color-white {
  color: $color-white;
}

.color-black {
  color: black;
}

.color-gray {
  color: $gray-color;
}

.color-secondary-grey {
  color: $secondary-grey;
}

.color-third-grey {
  color: $third-grey;
}

.color-green {
  color: $color-green;
}

.button-secondary {
  @include btn-secondary(13px 19px, 100%, 12px);
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.button-primary {
  @include btn-primary(13px 19px, 100%, 12px);
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.button-danger {
  @include btn-danger(13px 18px, 100%, 12px);
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.offer-p-slider-color {
  color: #8a9099;
}

.font-size-14 {
  font-size: $primary-font-size;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-dashboard {
  font-size: 14px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-20 {
  font-size: 20px;
}

.about-us-left-p {
  color: #878e99;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.hidden {
  display: none !important;
}

.show {
  display: inline-block;
}

.mb-85 {
  margin-bottom: 85px;
}

.contact-div {
  @include div-bg;
  width: 280px;
}

.how-usa2-works-right-div {
  @include div-bg($padding: 40px);
  margin-bottom: 32px;
  margin-left: -25px;
}

.why-usa2-div,
.flights-container {
  @include div-bg($padding: 24px 38px);
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.why-usa2-div {
  margin-right: 6px;
}

.transactions-div {
  -webkit-transition: $primary-transition;
  -o-transition: $primary-transition;
  transition: $primary-transition;

  .fill-balance-bank-icon img {
    height: unset;
  }
}

.transactions-div:hover {
  -webkit-box-shadow: 1px 1px 10px 1px #e4e4e4;
  box-shadow: 1px 1px 10px 1px #e4e4e4;
  cursor: pointer;
}

.offer-slider-container {
  @include div-bg($padding: 40px 74px 40px 40px);
  width: 100%;
  margin-top: 24px;
  height: 288px;
}

.login-input {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 12px;
  padding: 24px;
  padding-left: 55px;
  outline: none;
  color: #a9a9a9;
}

.loginInputDiv {
  margin-bottom: 16px;
}

.ms-87 {
  margin-left: 65px;
}

.bg-transparent {
  background: transparent;
}

.grey-border {
  border: 1px solid #ddd;
}

.red-border {
  border: 1px solid $color-red !important;
}

.bg-dark {
  background: rgba(255, 255, 255, 0.0373075);
  transition: $primary-transition;
}

.bg-white {
  background: $color-white;
}

.bg-orange {
  background: $bg-orange;
  transition: $primary-transition;
}

.bg-orange:hover {
  background: $bg-orange-hover;
}

.button {
  border-radius: 32px;
  border: none;
  transition: background-color 0.3s ease-in-out;
  // height: $primary-btn-height;
}

.primary-btn-w-h {
  min-width: 180px;
  width: 100%;
  height: $primary-btn-height;
}

.btn-create-ticket {
  width: 280px;
  height: 40px;
}

.faq-sidebar-btn {
  width: 80px;
  height: 41px;
}

.secondary-btn-w-h {
  width: 100%;
  max-width: 254px;
  min-width: 150px;
  height: 60px;
}

.secondary-btn-bg {
  background-color: #888888;
}

.secondary-btn-bg:hover {
  background-color: #a0a0a0;
}

/* Change the white to any color */

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.input-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.input-icon-right {
  position: absolute;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  margin-left: 24px;
  right: 15px;
  height: 60px;
  top: 0;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: #999;
    }
  }
}

.password-forgot-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 40px;
  color: #f68220;
}

.password-forgot-link {
  color: #f68220;
}

.orange-border {
  border: 1px solid #f68220;
}

.color-orange {
  color: #f68220 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  cursor: pointer;
}

::-webkit-file-upload-button {
  cursor: pointer;
}

.see-all {
  margin-bottom: 30px;
}

.logo-lang-div {
  margin-bottom: 60px;
}

.lang-switch-div {
  display: flex;
  background-color: #1f1f22;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  border-radius: 12px;
}

.lang-switch-text {
  margin-left: 8px;
}

.small-icon-div {
  width: 40px;
  height: 40px;
  border-radius: $round-circle;
}

.icon-dark-bg {
  background-color: #1f1f22;
}

.icon-gray-bg {
  background-color: $primary-bg-color;
  transition: $primary-transition;
}

.icon-dark {
  path {
    fill: $secondary-grey;
  }
}

.icon-gray-bg:hover {
  background-color: #ebe8e6;
}

.icon-light-bg {
  background-color: white;
}

.screen-comp-left-container {
  background-color: #161619;
  height: 100%;
  position: fixed;
  overflow-y: hidden;
  top: 0;
  left: 0;
  z-index: 10;
  width: calc(25% + 24px);
  min-width: 400px;
  padding: 55px;
  border-radius: 0 12px 12px 0;
}

// @media (max-width: 1740px) {
//   .screen-comp-left-container {
//     padding: 16px 55px;
//   }
// }

.screen-comp-left-container:hover {
  overflow-y: scroll;
  padding-right: 48px;
}

.wrapper-overflow-disable {
  overflow-y: hidden !important;
}

.screen-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.auth-page-right-div {
  background-color: rgb(129, 129, 129);
  height: 100vh;
}

.about-us-container {
  margin-top: 150px;
}

.about-us-title {
  font-size: 32px;
}

.why-usa2-title-padding-bottom {
  padding-bottom: 4px;
  margin-left: 30px;
}

.about-us-right-div {
  background-color: $bg-orange;
  position: absolute;
  right: 0;
  max-width: 405px;
  width: 100%;
  margin-right: 80px !important;
  border-radius: 12px;
  padding: 40px 50px 58px 50px;
}

.about-us-right-title {
  color: $color-white;
  line-height: 1.71;
  font-weight: 400;
}

.about-us-right-p {
  color: #ffe1cc;
  margin-top: 24px;
  line-height: 1.71;
  font-weight: 400;
}

.login-container {
  // overflow-y: hidden;

  .avatar {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    vertical-align: middle;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .empty {
    border: 2px solid #373c47;

    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      width: 50%;
      height: 50%;
      margin: auto;
      transform: translateY(-50%);
    }
  }
}

@media (max-width: 1740px) {
  .login-container {
    margin-top: auto;
  }
}

.login-inputs {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    border: 1px solid rgba(255, 255, 255, 0.12) !important;
    box-shadow: 0 0 0 30px #161619 inset !important;
    -webkit-box-shadow: 0 0 0 30px #161619 inset !important;
  }
}

.main-img-div {
  margin: 50px -174px 50px -150px;
}

.checkbox-save-div {
  margin-left: 15px;
  margin-top: 25px;
  margin-bottom: 31px;
}

.loginCheckboxDiv {
  position: relative;
  width: 18px;
  height: 18px;

  &::before {
    content: '';
    position: absolute;
    transition: all 100ms linear;
    background-color: #f68424;
    transform: scale(0);
  }
}

.rememberActive {
  &::before {
    left: 4px;
    top: 4px;
    right: 4px;
    bottom: 4px;
    border-radius: 2px;
    transform: scale(1);
  }

  .loginCheckboxColor {
    border-color: #f68424;
  }
}

.loginCheckbox {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  opacity: 0;
}

.loginCheckboxColor {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  border-radius: 3px;
  border: 1px solid $color-white;
}

.checkbox-save-span {
  font-size: $primary-font-size;
  margin-left: 10px;
}

.auth-p {
  font-size: 14px;
  font-weight: 700;
}

.why-usa2-container {
  padding-bottom: 80px;
  margin-top: 80px;
  margin-right: -30px;
  margin-left: -30px;

  .why-usa2-icon {
    path {
      fill: rgb(246, 132, 36);
    }
  }
}

.how-usa2-works {
  background-image: linear-gradient(223deg, #3c414d, #353a44);
  margin-left: -150px;
  margin-right: -174px;
  padding: 80px 150px 48px 150px;
}

.how-usa2-works-title {
  font-size: 32px;
  margin-left: -25px;
}

.pr-60 {
  padding-right: 60px;
}

.login-footer-terms {
  margin-bottom: 11px;
  text-decoration: underline;
}

.login-copyright {
  color: #6a7180;
  font-size: 11.5px;
  font-weight: 400;
}

.how-it-works-bullet {
  border: 2px solid #474e5c;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: inline-block;
}

.bullet-num {
  padding-top: 7px;
  text-align: center;
  font-size: 19px;
}

.how-it-works-bullet-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
  margin-left: -25px;
}

.add-tracking-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center !important;
  height: 100%;
  margin-top: -10px;
}

// .prices-right-div {
//   //margin-top: 189px;
// }

.contact-title {
  margin-top: 80px !important;
}

.swiper-btns-bg {
  background: $primary-bg-color;
  color: #8a9099;
  transition: $primary-transition;
}

.small-icon-primary-bg {
  background: $primary-bg-color;
}

.swiper-btns-bg:hover {
  @include btn-primary();
}

.screen-comp-right-container {
  background-color: $primary-bg-color;
  padding: $main-padding;
  height: 100vh;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden !important;
  width: 75% !important;
  // margin-left: calc(25% + 12px);
  margin-left: 25%;
}

.how-usa2-right-h3 {
  font-size: 16px;
  font-weight: 700;
}

.how-usa2-right-p {
  margin-top: 24px;
  line-height: 24px;
  color: #8a9099;
  font-weight: 400;
}

.how-usa2-right-p b {
  font-family: HelveticaBoldCaps;
}

.how-usa2-left-sticky {
  top: 0;
  position: sticky;
  margin-left: 30px;
}

.prices-img {
  margin-left: -150px;
  margin-top: 80px;
  max-width: 636px;
  width: 100%;
  height: auto;
}

.what-we-offer-container {
  padding-top: 80px;
  margin-right: -150px;
}

.flights-container .why-usa2-icon {
  margin-left: -13px;
}

.flight-arrived {
  background: #eaf8ed !important;
  svg {
    width: 24px;
    height: 20px;
  }

  path {
    fill: #30bf54;
  }
}

.flight-progress {
  svg {
    width: 24px;
    height: 20px;
  }

  path {
    fill: #f68424;
  }
}

.bold {
  font-weight: bold;
}

ol,
ul {
  padding-left: 0;
}

.branches-container {
  margin-top: 80px;
}

.branches-slider {
  height: 272px;
  border-radius: $primary-border-radius;
  background-color: $color-white;
  padding: 40px;
}

.branches-slider {
  .link {
    display: inline-block;
    bottom: 0;
    left: 0;
    //right: 40px;
    padding: 0px 40px 40px 40px;

    .button {
      width: 100%;
      max-width: none;
    }
  }
}

.branches-slider-container {
  margin-right: -160px;
}

.orange-line {
  width: 30px;
  height: 1px;
  border-bottom: 2px solid $bg-orange;
}

.orange-line-margin-for-whyusa2 {
  margin-left: 30px;
}

.margin-top-60 {
  margin-top: 60px;
}

.otp-input {
  width: 60px !important;
  height: 60px;
  border: 1px solid #dddddd;
  border-radius: 12px;
  background: #ffffff;
  text-align: center;
  margin-right: 8px;
}

.black-icon path {
  fill: black;
}

.otp-input:focus {
  border: 1px solid #f68220;
  background: rgba(246, 130, 32, 0.04);
}

.otp-send {
  background: rgba(246, 130, 32, 0.04);
  border-radius: 12px;
  padding: 13px 15px;
  max-width: 140px;
  width: 100%;
  margin: auto;
  margin-bottom: 80%;
  // transition: $primary-transition;

  p {
    color: $bg-orange;
    text-align: center;
  }
}

.h-20px {
  height: 20px;
}

.otp-send:hover {
  background: #ff9740;

  p {
    color: white;
  }
}

.mt-264 {
  margin-top: 50%;
}

.flight-icon {
  width: 56px;
  height: 56px;
  background: #fff3e7;
  border-radius: 50%;
}

.faq-div {
  background-color: $color-white;
  padding: 25px;
  border-radius: $primary-border-radius;
  transition: $primary-transition;
}

.faq-div:hover {
  color: $color-white;
  background: #373c47;

  .arrow-span path {
    fill: white;
  }
}

.opened-margin.true {
  margin-bottom: 10px;
}

.arrow-span {
  svg {
    transform: rotate(90deg);
    transition: 0.3s all ease-in-out;
  }
}

.arrow-span.true {
  svg {
    transform: rotate(-90deg);
  }

  path {
    fill: white;
  }
}

.faq-div-open {
  color: $color-white;
  background: #373c47;
}

.locker-details-bg {
  position: relative;
  height: 122px;
  background: #232326;
  border-radius: 12px;
  overflow: hidden;

  .abs-img {
    height: 120px;
    right: 0;
    top: 0;
    position: absolute;
    z-index: 2;

    path {
      fill: black;
    }
  }
}

.fill-balance-img {
  width: 40px;
  height: 40px;
}

.fill-balance-bank-icon {
  display: flex;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #ebe8e6;
  border-radius: 50%;
  width: 47px;
  height: 47px;
  overflow: hidden;

  img {
    max-width: 27px;
    height: 20px;
  }
}

.invoice-icon {
  path {
    fill: #f68424;
  }
}

.go-to-locker-btn {
  position: absolute;
  right: 20px;
  top: 41px;
  z-index: 3;
}

.go-to-locker-btn svg path {
  fill: $color-white;
}

.question-subdiv {
  background-color: #474e5c;
  padding: 25px;
  border-radius: $primary-border-radius;
  transition: $primary-transition;
  width: 100%;
}

.question-subdiv:hover {
  background-color: #535c6b;
}

.gray-line {
  width: 54%;
  height: 1px;
  border-bottom: 2px solid $color-grey;
  right: 0;
  position: absolute;
  margin-right: 60px;
  margin-top: 50px;
}

.btn-gray-bg {
  background-color: $color-grey;
  transition: $primary-transition;
}

.btn-gray-bg:hover {
  background-color: #ebe8e6;
}

.arrow-icon {
  right: 0;
  top: 0;
  margin-right: 22px;
  position: absolute;
}

path {
  transition: $primary-transition;
}

.svg-white-fill-hover:hover path {
  fill: white;
}

.multiple-colors-active path {
  fill: $bg-orange;
}

.settings-nav-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
}

.hover-multiple-colors {
  text-decoration: none;

  path {
    fill: #6c7380;
  }
}

.gray-icon {
  path {
    fill: #6c7380;
  }
}

.hover-multiple-colors:hover {
  path {
    fill: $bg-orange;
  }

  .hover-white {
    color: white;
  }
}

.faqlist-icon {
  width: 56px;
  height: 56px;
}

.search {
  height: 60px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 60px;
  padding-left: 52px;
  color: $secondary-grey;
}

.search-icon {
  position: absolute;
  top: 0;
  margin-top: 21px;
  margin-left: 24px;
  left: 0;
}

.select {
  background: $color-white;
  border-radius: 12px;
  color: #797c80;
  padding: 21px 16px;
  border: none;
  height: 60px;
  width: 100%;
}

.select-container {
  background: $color-white;
  color: #999;
  border-radius: 12px;
  padding: 16px 16px;
  border: 1px solid #dddddd;
  height: 60px;
  margin-top: 20px;
  width: 100%;
}

.gray-border {
  border: 1px solid #dddddd !important;
}

.w-full {
  width: 100%;
}

.hover-white {
  transition: $primary-transition;
}

.hover-white:hover {
  color: $color-white;
}

.w-325 {
  width: 100% !important;
}

.m-60 {
  margin: 60px;
}

.fill-balance-container {
  overflow-y: scroll !important;
  height: 100% !important;

  .fill-balance-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid $primary-bg-color;
    width: 100%;
    background: $primary-bg-color;
    height: 72px;
    padding: 12px;
    border-radius: $primary-border-radius;
  }

  .balance-active {
    background: #fef2e9;
    border: 2px solid $color-orange;
    transition: $primary-transition;
    color: $color-orange;
  }
}

.received-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 56px;
  height: 56px;
}

.fill-balance-btn {
  min-width: 144px;
  width: 100%;
  height: 40px;

  p {
    font-size: 12px;
  }
}

.logout-btn {
  min-width: 104px;
  height: 40px;
  padding-left: 10px;
  padding-right: 14px;
}

.color-red {
  color: $color-red;
}

.locker-img-div {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 25px;
  margin-left: 25px;
}

.settings-profile {
  background: $primary-bg-color;
  padding-right: 17px;
  padding-left: 17px;

  img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
  }
}

.settings-arrow {
  svg {
    transform: rotate(0) !important;
  }
}

.locker-img {
  max-width: 115px;
  width: 100%;
  height: 15px;
}

.rotate90 {
  transform: rotate(-90deg);
}

.rotate180 {
  transform: rotate(-180deg);
}

.alertIcon path {
  fill: $color-red !important;
}

.footer-div {
  margin-top: 30px;

  .appstore-icons {
    svg {
      max-width: 135px;
      width: 100%;
      height: auto;
    }
  }
}

.why-usa2-icon {
  background: #fff3e7;
  border-radius: 50%;
  max-width: 56px;
  width: 100%;
  height: 56px;
}

.w-h-40 {
  width: 40px;
  height: 40px;
}

.whyusa-margin {
  margin-top: 60px;
}

.transaction-card-icon {
  svg {
    width: 100%;
    height: 20px !important;
  }
}

.transaction-invoice {
  background: $primary-bg-color;
  height: 88px;
  border-radius: 12px;
  padding: 17px;
  width: 100%;
  color: #3b414d;

  &:hover {
    background-color: darken($primary-bg-color, 1%);
  }
}

.share-icon {
  path {
    stroke: $bg-orange;
  }
}

.notifications-container {
  .warning-icon {
    background-color: #fef5e8;

    path {
      fill: orange !important;
    }
  }

  .success-icon {
    background-color: #eaf8ed;

    path {
      fill: #30bf54 !important;
    }
  }

  .alert-icon {
    background-color: #feebeb;

    path {
      fill: #fa3f3f !important;
    }
  }
}

.usa2-address-div {
  height: 76px;
  background-color: $color-white;
  padding: 16px 24px;
}

.usa2-address-div:first-child {
  border-radius: 12px 12px 0 0;
}

.settings-icon path {
  fill: #3b414d;
  height: 20px;
}

.add-tracking-btn {
  height: 0;
  transition: $primary-transition;

  button {
    transform: scale(0);
    transition: $primary-transition;
    height: 0;
    width: 40%;
    margin-top: 2px;
    margin-bottom: 24px;
    visibility: hidden;
  }
}

.add-tracking-btn.true {
  height: 70px;

  button {
    display: block;
    height: 50px;
    transform: scale(1);
    visibility: visible;
  }
}

.add-tracking-btn-new {
  height: 0;
  transition: $primary-transition;

  .add-tracking-flag {
    transform: scale(0);
    transition: $primary-transition;
    // height: 0;
    visibility: hidden;
  }

  button {
    transform: scale(0);
    transition: $primary-transition;
    height: 0;
    width: 100%;
    margin-top: 2px;
    margin-bottom: 24px;
    visibility: hidden;
  }
}

.add-tracking-btn-new.true {
  height: 100%;

  .add-tracking-flag {
    // height: 15px;
    transform: scale(1);
    visibility: visible;
  }

  button {
    display: block;
    height: 50px;
    transform: scale(1);
    visibility: visible;
  }
}

.steps-container {
  display: flex;
  margin-right: 70px;
  justify-content: space-between;
  align-items: center;
  width: 50px;

  .step-icon {
    width: 8px;
    border-radius: 50%;
    height: 8px;
    background: $primary-bg-color;
  }

  .active-step {
    background: #f68424;
  }
}

.linked-locker-container {
  padding: 32px;
  background: $primary-bg-color;
  border-radius: $primary-border-radius;

  .locker-icon {
    width: 56px;
    height: 56px;
  }
}

.courierBtn {
  width: fit-content;
  // max-width: ;
  text-align: center;
  padding: 20px 42px;
  margin-bottom: 24px;
}

.flightSection {
  background-color: $color-white;
  border-radius: $primary-border-radius;
  margin-bottom: 8px;
  padding: 24px 16px 18px 16px;

  .flightDetails {
    font-size: 14px;
    padding: 0 16px;
    margin-bottom: 20px;

    .flightNumberInfo {
      color: $secondary-grey;
      font-family: HelveticaBoldCaps;
    }

    .addressInfo {
      color: $third-grey;
    }

    .changableBranch {
      color: $bg-orange;
      cursor: pointer;
    }

    .Locker {
      max-width: 63px;
    }
  }

  .status {
    font-size: 14px;
    padding: 24px 30px;
    border-radius: $primary-border-radius;

    .statusIconDiv {
      svg {
        height: 15.72px;
        width: 16.68px;
        min-width: 16.68px;
        margin-right: 2.15%;
      }
    }
  }

  .close-modal-btn {
    svg path {
      fill: $color-white !important;
    }

    .closeIcn path {
      stroke: $color-white;
    }
  }
}

.awaiting,
.inProgress {
  padding: 10px;

  .eachPackage {
    margin-top: unset;
  }
}

.inProgress {
  .status {
    @include inProgress;
  }
}

.returned {
  .status,
  svg path {
    @include returned;
  }
}

// .arrived {
//   .status,
//   svg path {
//     @include arrived;
//   }
// }

// .sent {
//   .status,
//   svg:not(.Locker) path {
//     @include sent;
//   }
// }

.greenBlock {
  color: #30bf54;
  background-color: #eaf8ed;
  svg:not(.Locker) path {
    color: #30bf54;
    background-color: #eaf8ed;
    fill: #30bf54;
  }
}

.invoice {
  .status {
    @include invoice;
  }
}

.nonChangableInfo {
  padding: 8px 0;

  .sidebarPackageIcon {
    margin-right: 24px;

    svg path {
      fill: #3b414d;
    }
  }

  .infoDiv {
    padding: 5.5px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .infoTitle {
    font-family: HelveticaNeueLTGEO-55Roman;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $third-grey;
    margin-bottom: 5px;
  }

  .info {
    font-family: HelveticaNeueLTGEO-55Roman;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    // font-feature-settings: 'case' on;
    color: $secondary-grey;
  }

  .invoiceDownload {
    background-color: $bg-green;
    margin-left: auto;

    svg {
      width: 16px;
      height: 16px;
    }

    svg path {
      fill: $color-green;
    }
  }

  .payBtn {
    margin-left: auto;
    align-self: center;

    .invoicePayment {
      background-color: red;
      height: 40px;
      width: 100px;
      border-radius: 40px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: $color-white;
      font-family: HelveticaBold;
      margin: unset;
    }
  }
}

.changableInfo {
  background: $primary-bg-color;
  border-radius: $primary-border-radius;
  padding: 16px;
  transition: $primary-transition;
  border: 2px solid $primary-bg-color;

  .invoiceContainer {
    max-width: 88%;

    .invoiceNameDiv {
      max-width: 75%;
      overflow: hidden;
      cursor: pointer;

      .invoiceName {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .image {
    border-radius: 50%;
    // width: 47px;
    // height: 47px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .uploadedInvoice {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
    color: $secondary-grey;
  }

  .ChangablePackageDetailsTitle {
    color: $secondary-grey;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    overflow: hidden;
    word-break: break-word;
  }

  .fadedContent {
    font-family: HelveticaNeueLTGEO-55Roman;
    color: $third-grey;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .sidebarPackageIcon {
    background-color: $color-white;
    width: 56px;
    min-width: 56px;
    height: 56px;
    margin-right: 16px;

    svg {
      path {
        fill: $secondary-grey;
      }

      fill: $secondary-grey;
    }
  }

  .actionIcons {
    padding: 0 8px;
    cursor: pointer;
    position: relative;

    svg {
      path {
        stroke: unset;
        fill: $third-grey;
      }

      stroke: unset;
      fill: $third-grey;
      width: 20px;
      height: 20px;
    }
  }

  .uploadInput {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .tickIconDiv {
    background-color: $color-orange;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    align-self: center;

    svg path {
      fill: $color-white;
    }
  }
}

.changableInfo.userAvatar {
  margin-bottom: 22px;
}

.changableInfo:nth-child(1) {
  border-radius: $primary-border-radius;
}

.changableInfo:hover {
  background-color: $bg-grey-hover;
  border-color: $bg-grey-hover;
}

.selectedDiv {
  background-color: $bg-orange-selected;
  border: 2px solid $color-orange;
}

.selectedDiv:hover {
  background-color: $bg-orange-selected;
  border: 2px solid $color-orange;
}

.changableInfo-active {
  background: $bg-orange !important;
  border: none;

  .actionIcons {
    svg {
      path {
        fill: $color-white;
        stroke: $color-white;
      }
    }
  }

  .ChangablePackageDetailsTitle,
  .fadedContent {
    color: white;
  }
}

.optionsIcons {
  position: relative;
  cursor: pointer;

  .packagePreview {
    position: absolute;
    display: none;
    width: 100%;
    top: 100%;
    right: 0;
    background-color: $color-white;
    border-radius: $primary-border-radius;
    width: fit-content;
    height: fit-content;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.08);
    z-index: 9;
    padding: 16px;

    h3 {
      font-size: 14px;
      line-height: 16.8px;
      color: $secondary-grey;
      margin-top: 3%;
      margin-bottom: 10%;
      white-space: nowrap;
      font-family: HelveticaNeueLTGEO-55Roman;
    }

    button p {
      margin: 0 auto;
    }
  }

  .tooltipDiv {
    margin-left: 8px;
    position: relative;

    .tooltipMessageWrap {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .tooltipMessageWrap.insuranceTooltip {
      width: 220px;
    }

    .tooltipMessage {
      background: #373c47;
      color: $color-white;
      text-align: center;
      padding: 10px 13px;
      border-radius: 12px;
      box-sizing: border-box;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: none;
      min-width: 100px;
    }

    .tooltipMessageWrap::after {
      content: '';
      display: none;
      width: 10px;
      height: 6px;
      background: #373c47;
      margin: -1.1px auto 0;
      clip-path: polygon(50% 100%, 0 0, 100% 0);
    }
  }

  .tooltipDiv:hover {
    .tooltipMessage,
    .tooltipMessageWrap::after {
      display: block;
    }
  }

  .iconDiv {
    background-color: $primary-bg-color;
    transition: all 200ms linear;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;

    svg {
      width: 16px;
      height: 16px;
      object-fit: contain;

      path,
      rect {
        fill: #878e99;
      }
    }
  }

  .securityIcon {
    svg {
      width: 21px;
      height: 21px;
    }
  }

  .activatedService {
    background-color: #eaf8ed;

    svg {
      path,
      rect {
        fill: #30bf54;
      }
    }
  }

  .toBeActivatedService {
    background-color: #fa3f3f;

    svg {
      path,
      rect {
        fill: $color-white;
      }
    }
  }

  .iconDiv:hover {
    background-color: #30bf54;
  }

  .toBeActivatedService:hover {
    background-color: #feebeb;

    svg {
      path {
        fill: #fa3f3f;
      }
    }
  }
}

.modalText {
  text-align: center;
}

.notification-icon {
  background: #373c47;

  path {
    fill: white;
  }
}

.active {
  position: relative;

  .packagePreview {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 100%;
    right: 0;
    background-color: $color-white;
    border-radius: $primary-border-radius;
    width: fit-content;
    height: fit-content;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.08);
    z-index: 9;
    padding: 16px;

    h3 {
      font-size: 14px;
      line-height: 16.8px;
      color: $secondary-grey;
      margin-bottom: 14%;
    }

    div {
      background-color: $color-white;
      cursor: default;
      width: 100%;

      button:first-child {
        margin-right: 5.5%;
      }
    }
  }

  .packagePreview::before {
    content: '';
    position: absolute;
    top: -5px;
    right: 7%;
    width: 10px;
    height: 6px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    background-color: $color-white;
  }
}

.sidebarPackageIcon {
  width: 56px;
  height: 56px;
  background-color: $primary-bg-color;
}

.cursor-disabled {
  color: #a9a9a9;
}

.cursor-disabled:hover {
  cursor: not-allowed;
}

.customColumnToggle {
  padding: 13.5px 0 !important;
}

.customToggleFont {
  font-family: HelveticaNeueLTGEO-55Roman;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: $third-grey;
}

.sidebarButtonDiv {
  padding-right: 60px;
  margin-top: auto;
}

.contact-a {
  color: black;
}

.responsive-footer,
.sidebar-close-btn-responsive {
  display: none;
}

.w-h-48 {
  width: 48px;
  height: 48px;
}

.responsive-navbar {
  .burger-menu-btn {
    width: 40px;
    height: 40px;
    background: #373c47;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 12px;
    padding-bottom: 12px;

    path {
      stroke: white;
    }

    span {
      display: block;
      left: 0;
      right: 0;
      width: 16px;
      height: 2px;
      background: white;
      margin: auto;
    }
  }

  .burger-menu-container {
    background: #282c33;
    position: fixed;
    top: 0;
    right: 0;
    transition: all 0.3s ease-in-out;
    margin-top: -250%;
    visibility: hidden;
    width: 100%;
    padding: 20px;
    opacity: 0;

    overflow: auto;
    height: 100vh;

    .burger-icons-div {
      width: 20px;
      margin-right: 15px;
    }

    .barcode-burgermenu {
      path {
        fill: #9da4b3;
      }
    }
  }

  .burger-menu-container.true {
    visibility: visible;
    margin-top: 0;
  }

  .burger-menu-container,
  .true {
    opacity: 1;
    z-index: 10;
    visibility: visible;
  }
}

.responsive-show {
  display: none;
}

.faq-container,
.flights-main-container {
  padding-bottom: 40px;
}

.faq-container {
  iframe {
    width: 100%;
  }
}

.faq-icon {
  svg {
    width: 20px;
    height: 20px;
  }

  path {
    fill: #3b414d;
  }
}

.branches-page-container {
  padding-bottom: 24px;

  .branch-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.sidebar-child-scroll {
  // height: 65vh;
  overflow-y: scroll;
}

.registration-container {
  .swiper-slide {
    margin-bottom: unset !important;
    height: calc(100% - 68px) !important;
  }
}

.sidebar-child-scroll-registration {
  height: 55vh;
}

.registration-first-slide {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  height: 80vh;
}

.sidebar-child-scroll-faq {
  height: 80%;
  overflow-y: scroll;
}

.sidebar-form {
  .sidebar-child-scroll {
    height: 90% !important;
  }
}

.balance-container {
  padding: 8px !important;
}

.SpinnerLoading {
  width: 24px;
  height: 24px;
  animation-name: rotation;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 3s;

  path {
    fill: $color-white !important;
  }
}

.user-policy-container {
  height: 100vh;

  iframe {
    height: 100%;
    width: 100%;
  }
}

.responsive-notification-icon {
  path {
    fill: white;
  }
}

.app-suggest {
  display: none;
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.prices-img-container {
  background: url('../assets/images/prices-img.webp') center center no-repeat;
  height: 440px;
  background-size: cover;
}

.insurance-table {
  color: #8a9099;
}

.insurance-table td {
  border-top: 1px solid #ccc;
  padding: 5px 15px;
}

.prices-row {
  // margin-left: -150px;
  // margin-right: -150px;
  margin-top: 80px;
}

@media (max-width: 991px) {
  .prices-row {
    margin-left: -24px;
    margin-right: -24px;
  }
}

.add-tracking-buttons {
  display: flex;
  gap: 10px;
}

@media (max-width: 768px) {
  .add-tracking-buttons {
    display: block;
    gap: 0;
  }
}

@media (max-width: 1380px) and (min-width: 1200px) {
  .screen-comp-right-container {
    padding-left: 150px;
    padding-right: 100px;
  }
}

@media (max-width: 1447px) and (min-width: 1200px) {
  .whyusa-margin {
    margin-top: 100px;
  }
}

@media (max-width: 991px) {
  .whyusa-margin {
    margin-top: 0px;
  }
}

@media (max-width: 1351px) and (min-width: 1200px) {
  .customer-cards {
    .pr-60 {
      padding-right: 0 !important;
    }
  }
}

.Registration {
  .swiper-wrapper {
    align-items: flex-start;
  }

  .swiper-slide {
    height: 95% !important;
    overflow-y: scroll;
  }

  .sidebar-child-scroll {
    max-height: unset !important;
  }
}

.passwordStep {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .mt-264 {
    margin-top: unset;
  }
}

@media (max-width: 1199px) {
  .invoice-link {
    width: 100px !important;
  }
}

@media (max-width: 768px) {
  .add-tracking-icon {
    visibility: hidden !important;
  }

  .app-suggest {
    background-color: #373c47;
    padding: 10px 5px;
    height: 70px;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    color: white;
    font-size: 10px;

    .app-icon {
      border: 1px solid white;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      margin-right: 5px;

      svg {
        width: 10px;
        height: 10px;
      }

      path {
        stroke: white;
      }
    }

    .inner-div {
      display: flex;
      flex-direction: row;
    }

    .app-btn {
      width: 100px !important;
      height: 30px;

      p {
        font-size: 10px !important;
      }
    }

    .app-text {
      width: 120px;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 12px;
      margin-right: 10px;
    }
  }

  .branch-row {
    grid-template-columns: 1fr !important;
  }

  .eachPackage {
    .button-danger {
      width: fit-content !important;

      p {
        font-size: 10px;
      }

      padding: 5px 10px;
      height: 32px;
    }

    .optionsIcons {
      .iconDiv {
        display: none;
      }
    }
  }

  .detailsDots {
    padding: 10px;
    height: 100% !important;
    margin: auto 0;

    svg {
      path {
        fill: #8a9099;
        height: 18px;
      }
    }
  }

  .flightSection {
    .status {
      padding: 15px;
    }
  }
}

.height-calculated {
  height: calc(100% - 68px);
}

.height-100 {
  height: 100%;
}

.height-50 {
  height: 50%;
}

@media (max-width: 1199px) {
  .steps-container {
    margin-right: 10px;
  }

  .why-usa2-div {
    margin-right: 0px;
  }

  .registration-slider {
    .sidebar-child-scroll {
      max-height: 60vh;
    }
  }

  .mySwiper {
    width: 100% !important;
  }

  .Registration {
    .mySwiper {
      height: calc(98% - 28px);
    }

    .swiper-slide {
      height: 100% !important;
    }

    .sideBarChildComponent {
      height: 100%;
    }

    .sidebar-child-scrol {
      height: 100%;
    }

    .pr-60 {
      height: 100%;
    }

    .mt-264 {
      margin-top: 10px;
    }

    .passwordStep {
      margin-top: 1.5rem !important;
      height: calc(100% - 1.5rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .pr-60 {
    padding-right: 0 !important;
  }

  .sidebar-form {
    max-height: 95%;

    .sidebarButtonDiv {
      margin-top: 16px;
    }
  }

  .footer-div,
  .how-it-works-bullets-container {
    display: none;
  }

  .screen-comp-right-container {
    margin: $responsive-margin;
    width: 100% !important;
    padding: 0 24px;
    min-height: 100vh;
    height: 100%;
    overflow-y: auto;
  }

  .mb-85 {
    margin-bottom: 20px !important;
  }

  .navbar-div {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  .transactions-container {
    margin: $responsive-margin;
  }

  .usa2-address-container,
  .settings-container,
  .faq-container,
  .tab-container {
    margin: auto;
  }

  .prices-img,
  .prices-right-div,
  .why-usa2-container,
  .about-us-container,
  .branches-page-container,
  .flights-main-container,
  .branches-container {
    margin: auto;
    padding-top: 50px !important;
  }

  .prices-right-div,
  .contact-container,
  .what-we-offer-container,
  .branches-container,
  .faq-container,
  .flights-main-container,
  .branches-page-container,
  .about-us-container {
    margin: $responsive-margin;
  }

  .what-we-offer-container {
    padding-top: 50px;
  }

  .prices-right-div {
    margin-left: 24px;
    margin-right: 24px;
  }

  .about-us-right-div {
    margin-top: 50px;
  }

  .screen-comp-left-container {
    width: 100%;
    min-width: 100%;
    padding: 20px 24px;
    position: static;
    height: auto;
    border-radius: 0 0 12px 12px;
  }

  .screen-comp-left-container:hover {
    padding-right: 17px;
  }

  .why-usa2-container {
    padding-bottom: 45px;
    margin: $responsive-margin;
  }

  .about-us-right-div {
    position: static;
    max-width: 100%;
  }

  .margin-top-60 {
    margin-top: 0;
  }

  .why-usa2-title-padding-bottom {
    margin-left: 0;
    padding-bottom: 24px;
  }

  .how-usa2-works {
    padding: 24px 40px 5px 170px;
    margin-right: -24px;
  }

  .how-usa2-left-sticky {
    position: static;
    margin-left: 24px;
    margin-bottom: 24px;
  }

  .offer-slider-container {
    padding: 24px;
    height: 300px;
    width: 90%;
    margin: auto;
  }

  .prices-img-container {
    max-width: 100%;
    min-height: auto;

    .prices-img {
      width: 100%;
      height: auto;
    }
  }

  .about-us-title {
    padding-bottom: 0;
  }

  .branches-container .navbar-div {
    display: initial;
  }

  .branches-container {
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 50px;
  }

  .branches-slider-div {
    display: flex !important;
    flex-direction: column;
  }

  .main-img-div {
    margin-right: -24px;
  }

  .row > * {
    padding: 0 !important;
  }

  .contact-title {
    margin-top: 30px !important;
    margin-left: 10px;
  }

  .faq-div {
    padding: 10px;
  }

  .flights-container {
    padding: 15px;
    margin: auto;
    margin-bottom: 10px;
  }

  .flights-container .why-usa2-icon {
    margin-bottom: 40px;
    margin-left: 0;
  }

  .responsive-footer {
    display: flex;
    background: #161619;
    height: 100%;
    margin: auto;
    padding: 50px 24px;
  }

  .button,
  .responsive-footer-div {
    width: 100% !important;
  }

  .courierBtn {
    width: fit-content !important;
  }

  .sidebar {
    width: 100% !important;
    border-radius: 0;
    padding: 20px;
    z-index: 1000 !important;
    overflow-y: auto !important;
  }

  .legal-person-1step {
    margin-bottom: 245px !important;
  }

  .sidebar-close-btn-web {
    visibility: hidden;
  }

  .sidebar-close-btn-responsive {
    visibility: visible;
  }

  .close-modal-btn {
    padding-bottom: 2px;
    margin-bottom: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: static;
    top: 0;
    margin-left: 0;
    margin-top: -30px;
    z-index: 10;
  }

  .responsive-hidden {
    display: none !important;
  }

  .responsive-show {
    display: block;
  }

  .login-container {
    margin-top: 0;
  }

  .close-responsive-burger {
    margin-right: 4px;
    margin-top: -8px;
  }

  .close-responsive-burger path {
    fill: white !important;
  }

  .lang-switch-div {
    background-color: #373c47;
    padding: 8px 16px;
  }

  .how-usa2-works-right-div {
    margin-left: 0;
  }

  .branches-slider-container {
    margin-right: 0;
  }

  .select-region {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }

  .branches-title-text {
    margin-left: auto;
  }

  .logo-lang-div {
    margin-bottom: 25px;
  }

  .hr {
    background: #373c47;
    height: 1px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.not-found-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  flex-direction: column;

  div {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #f0f0f0;
  }

  p {
    color: #868686;
    font-size: 16px;
    margin-top: 24px;
  }
}

.share-answer-container {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  color: #878e99;
  background: #f7f6f5;
  border-radius: 12px;
  padding: 16px;
}

.mt-50 {
  margin-top: 50px;
}

.orange-icon {
  path {
    fill: orange !important;
  }
}

.hidden {
  display: none !important;
}

.agreement-container {
  background: white;
  min-height: 100vh;
  z-index: 30000;

  iframe {
    height: calc(100vh - 90px);
    width: 100%;
  }

  .agreement-button-div {
    padding-bottom: 10px;
  }

  button {
    width: 150px;
    margin: auto;
  }
}

.view-map-p {
  font-family: HelveticaNeueLTGEO-55Roman !important;
}

.ipay-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .ipay-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 320px;
    background: white;
    border-radius: 12px;
    margin-top: 50px;
    padding: 16px;
  }

  .card-details-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 60px;
    background: $primary-bg-color;
    border-radius: 12px;
    padding: 12px;
  }

  .ipay-border {
    margin: 12px 0;
    border-top: 2px solid $primary-bg-color;
    padding: 12px 0;
    border-bottom: 2px solid $primary-bg-color;
  }

  .save-card-button {
    background: transparent;
    border-bottom: 2px solid $bg-orange;
    border-radius: 0;
    margin: 0 40px;
  }

  .transaction-card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 50%;
    padding: 5px;
    width: 40px;
    height: 40px;
  }

  .transaction-success {
    top: -20px;
    width: 40px;
    height: 40px;
    left: 43.9%;
    position: absolute;
    background: #29a649;
    border-radius: 50%;
    padding: 8px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .transaction-error {
    top: -25px;
    width: 50px;
    height: 50px;
    left: 42.2%;
    position: absolute;
    border-radius: 50%;
    padding: 8px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.social-img-sizes {
  svg {
    width: 20px;
    height: 20px;
  }

  path {
    fill: white;
  }
}

.swiper-wrapper {
  display: flex;
  align-items: center;
}

.swiper-slide {
  height: unset !important;
}

.search-container {
  path {
    fill: #878e99;
  }
}

.responsive-category-icon {
  path {
    fill: #878e99;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.saved-username-container {
  display: flex;
  margin-top: 36px;
  justify-content: flex-start;
  align-items: center;
  //flex-direction: column;
  margin-bottom: 36px;
}

.profile-input-error {
  margin-bottom: 10px;
}

.pagination {
  flex-wrap: wrap;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;

  .last-page-div {
    position: relative;

    .arrow-1,
    .arrow-2 {
      position: absolute;
      top: 0;
      right: 0;
    }

    .arrow-1 {
      margin-right: -16px;
      margin-top: -12px;
    }

    .arrow-2 {
      margin-right: -9px;
      margin-top: -12px;
    }
  }
}

.page-test {
  width: 40px;
  height: 40px;
  background-color: red !important;
  color: red !important;
}

.page-item {
  margin: 0 3px;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 6px;
  padding: 5px;
  margin-bottom: 6px;

  .page-link {
    border: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0 !important;
    color: #878e99;

    &:focus {
      box-shadow: none;
      background-color: transparent;
    }

    &:hover {
      background-color: transparent;
    }
  }

  &.active .page-link {
    text-align: center;
    padding: 0 !important;
    height: 100%;
    line-height: 32px;
    background-color: #f68424;
    color: white;
    border-radius: 4px;
  }
}

.package_note_danger {
  color: red;
  padding: 10px 10px 0 5px;
  font-size: 13px;
}

.user-box {
  margin-right: 15px;
}

.user-box .img {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
}

.user-box .img.empty {
  border: 2px solid #373c47;
}

.img.empty img {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  width: 50%;
  height: 50%;
  margin: auto;
  transform: translateY(-50%);
}

.user-box .img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-box .img.empty img {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  width: 50%;
  height: 50%;
  margin: auto;
  transform: translateY(-50%);
}

.kommunicate-custom-iframe {
  z-index: 9 !important;
}

@media screen and (max-width: 1490px) {
  .font-size-16 {
    font-size: 14px;
  }

  .font-size-dashboard {
    font-size: 11px;
  }
}

@media (max-width: 1199px) and (min-width: 769px) {
  .registration-first-slide {
    padding-right: 50px !important;
  }
}

.lockerFirstStep {
  .changableInfo {
    height: 92px;
  }
}

.find-package-container {
  .package-input {
    path {
      fill: #878e99;
    }

    margin-right: 30px;
    width: 100%;
    height: 50px;

    input {
      height: 100%;
      margin-bottom: 0;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #ebe8e6;
      outline: none;
      border-radius: unset;
      transition: border-color 0.3s;
      color: black;
    }

    input:focus {
      border-bottom: 1px solid $bg-orange;
    }

    .input-icon {
      margin-left: 0;
    }

    .login-input {
      padding-left: 30px;
    }
  }

  .package-button {
    height: 50px;
    max-width: 172px;
    width: 100%;
  }

  .find-package-div {
    width: 75%;
  }

  p {
    font-weight: 400;
  }
}

@media (max-width: 748px) {
  .find-package-div {
    width: 100% !important;
  }
}

.locker-settings {
  .changableInfo .ChangablePackageDetailsTitle {
    overflow: unset;
  }
}

.custom-map-control-button {
  width: 40px;
  height: 40px;
  background-color: white;
  position: relative;
  border: none;
  border-radius: 50%;
  margin-right: 10px;
}

.custom-map-control-button::after {
  content: '';
  width: 20px;
  height: 20px;
  clip-path: polygon(0 35%, 100% 0, 75% 100%, 50% 50%);
  background: #2d95e5;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-60%, -45%);
}

.optionsIcons {
  .img-block {
    @media screen and (min-width: 900px) {
      width: 200px;
    }

    width: auto;
    text-align: center;
  }
}

.country-flag {
  width: auto;
  margin-left: 10px;
}

.pc-input {
  border-radius: 12px;
  padding: 24px;
  height: 60px;
  border: 1px solid #878e995e;
  margin-top: 10px;
  margin-right: 10px;
  width: 220px;
}

.flex-1 {
  flex: 1;
}

.font-14 {
  font-size: 14px !important;
}

@media (max-width: 748px) {
  .font-14 {
    font-size: 13px !important;
  }
}

.user-select-none {
  user-select: none;
}

.h-36 {
  height: 36px !important;
}

.pop-notifications-container {
  width: 50%;
  margin: 10px auto;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 12px;
}

.pop-notifications-container p {
  margin: 10px;
}

.pop-notifications-container strong {
  font-family: HelveticaBoldCaps;
}

.pop-notifications-container h2 {
  font-size: 22px !important;
}

@media screen and (max-width: 900px) {
  .pop-notifications-container {
    width: 100% !important;
    margin: 0 !important;
    border: 0 !important;
  }
}

.notificationDeleteBtn {
  color: $color-white;
  padding: 13px 18px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-left: 4px !important;
  }

  svg {
    width: 14px;
    height: 14px;

    path {
      fill: $color-white;
    }
  }
}

.bold-text {
  font-family: HelveticaBold;
}

.web-notification-item p {
  margin: 10px 0;
}

.pulsating-circle {
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: #01a4e9;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #f68424;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.circle {
  border-radius: 50%;
  background-color: #f68424;
  width: 15px;
  height: 15px;
  opacity: 0;
  animation: scaleIn 1s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
}

@keyframes scaleIn {
  from {
    transform: scale(0.5, 0.5);
    opacity: 0.5;
  }
  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 10px;
  height: 15px;
  width: 15px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.blob.white {
  background: white;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.blob.red {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.blob.orange {
  background: rgba(255, 121, 63, 1);
  box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
  animation: pulse-orange 2s infinite;
}

@keyframes pulse-orange {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
}

.blob.yellow {
  background: rgba(255, 177, 66, 1);
  box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
  animation: pulse-yellow 2s infinite;
}

@keyframes pulse-yellow {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
  }
}

.blob.blue {
  background: rgba(52, 172, 224, 1);
  box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
  animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}

.blob.green {
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}

.blob.purple {
  background: rgba(142, 68, 173, 1);
  box-shadow: 0 0 0 0 rgba(142, 68, 173, 1);
  animation: pulse-purple 2s infinite;
}

@keyframes pulse-purple {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(142, 68, 173, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 0);
  }
}

#wave {
  height: 40px;
  width: 40px;
  margin-left: 40px;
}

@for $i from 1 through 9 {
  #Line_#{$i} {
    animation: pulse 1s infinite;
    animation-delay: $i * 0.15s;
  }
}

@keyframes pulse {
  0% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }

  50% {
    transform: scaleY(0.7);
    transform-origin: 50% 50%;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }
}
