@import '../../scss/Variables';

.float-label {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.float-label input,
.float-label textarea {
  padding: 14px 16px 0 10px;
  outline: 0;
  width: 100%;
  border-radius: 12px;
  height: $primary-btn-height;
  margin-bottom: 22px;
  border: 1px solid #ddd;
  background: transparent;
  font-size: 14px;
}

.float-label .inputWithRightIcon {
  padding-right: 45px;
}

.float-label label {
  font-size: 14px;
  padding: 0 15px;
  color: #999;
  pointer-events: none;
  position: absolute;
  top: 45%;
  transform: translate(0, -100%) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
}

.validation-error-text {
  margin-top: -25px;
  max-height: 14px;
  margin-bottom: 20px;
}

.float-label:focus-within label {
  transform: translate(0, -130%) scale(0.75);
  padding: 0 15px;
  //top: 45%;
  //margin-left: 3px;
}

.float-label .Active {
  transform: translate(0, -130%) scale(0.75);
  padding: 0 15px;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-97_5 {
  width: 97.5% !important;
}