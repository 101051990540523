@import 'src/scss/Variables';

.alert-container {
  position: fixed;
  z-index: 300;
  right: 0;
  top: 0;
  width: 100%;
  transition: all 1200ms ease-out;
  border-radius: 0 0 12px 12px;
  opacity: 0;

  .container-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0;
    opacity: 0;
  }

  p {
    color: $color-white;
    font-size: 16px;
  }
}

.alert-success {
  background: #47ba9c;
}

.alert-error {
  background: #d80027;
}

.alert-container.false {
  margin-top: -30%;
  opacity: 0;
  height: 0;
}

.alert-container.true {
  margin-top: 0;
  opacity: 1;
  height: 116px;

  .container-div {
    opacity: 1;
    height: 100%;
  }
}

@media (max-width: 960px) {
  .alert-container {
    transition: all 800ms ease-out;

    .container-div {
      margin-left: 5%;
      margin-right: 5%;
    }

    p {
      font-size: 12px;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
