.hc-packages {
  display: flex;
  flex-wrap: wrap;
  flex: 50%;
  justify-content: flex-start;
  gap: 15px;
  margin-left: 30px;
}

.hc-package-group {
  width: 100%;
  font-size: 22px;
  font-weight: 900;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.hc-package-item {
  width: 49%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
}

.hc-menu-item {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

.hc-menu-item:hover{
  border: 1px solid #f68424;
}

.hc-breadcrumb{
  font-family: HelveticaBoldCaps, serif;
  font-size: 14px;
}

.hc-breadcrumb:hover{
  color: black;
  cursor: pointer;
}

@media (min-width: 768px) {
  .hc-container{
    padding-right: 20px;
  }
}

@media (max-width: 991px) {

  .hc-package-item {
    flex-basis: 100%;
  }
}

@media (min-width: 1200px) and (max-width: 1450px) {
  .hc-package-item {
    flex-basis: 100%;
  }
}
