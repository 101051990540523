.sf {
  background-color: #f7f6f5;
  min-height: 100vh;
}

.sf-header {
  background-color: #171717;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.sf-language-switcher {
  padding: 12px 16px;
  background-color: #262626;
  border-radius: 12px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  transition: all ease-in-out 300ms;
}

.sf-language-switcher img {
  width: 20px;
  height: 14px;
}
.sf-language-switcher div {
  color: white;
  font-size: 12px;
}

.sf-language-switcher:hover {
  background-color: #404040;
}

.sf-caption {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sf-input {
  outline: none;
}

.sf-body {
  background-color: white;
  border-radius: 0.2rem;
  box-shadow: 0 2px 6px 0 rgb(238 242 247);
  border: 1px solid #ebe8e6;
}

.sf-result {
  padding: 20px;
  text-align: center;
}

.form-control:focus {
  border-color: #f68424;
}

.sf-radio-custom:checked {
  background-color: #f68424 !important;
  border-color: #f68424 !important;
}

.sf-radio-custom:focus {
  box-shadow: 0 0 4px 2px rgba(246, 132, 36, 0.9);
  -webkit-box-shadow: 0 0 4px 2px rgba(246, 132, 36, 0.9);
  -moz-box-shadow: 0 0 4px 2px rgba(246, 132, 36, 0.9);
}

.sf-form-input {
  -webkit-text-fill-color: black !important;
}
