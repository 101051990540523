@import './Variables.scss';
@import './Mixins.scss';

.dashboard-categories-item {
  width: 100%;
  transition: $primary-transition;
}

rect {
  transition: $primary-transition;
}

.mt-25 {
  margin-top: 27px;
}

.dashboardCategories {
  width: 20%;
}

.categories-numbers-absolute {
  position: absolute;
  top: 8px;
  right: 8px;

  div {
    background: #ebecef;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    color: $gray-color;
    transition: $primary-transition;

    p {
      text-align: center;
      font-size: 12px;
      line-height: 22px;
    }
  }
}
.largeNumber .categories-numbers-absolute {
  div {
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    font-size: 11px;
  }
}

.tab-icon {
  svg {
    height: 20px;
    width: 26px;
  }

  path {
    fill: $third-grey
  }
}

.dashboard-categories-item:hover {
  background: $bg-orange;
  cursor: pointer;

  .color-gray {
    color: white;
  }

  path, rect {
    fill: white;
  }


  .modified-flight-icon path {
    fill: white;
  }
}

.modified-flight-icon path {
  fill: $gray-color;
}

.dashboard-categories-item:hover .dashboard-categories-number {
  color: $bg-orange;
}

.categories-active {
  background: $bg-orange;
  cursor: pointer;

  .color-gray {
    color: white;
  }

  .modified-flight-icon path {
    fill: white;
  }
}

.categories-active path, .categories-active rect {
  fill: white;
}

.categories-active .dashboard-categories-number {
  color: $bg-orange;
}

@media screen and (max-width: 1199px) {
  .dashboardRow {
    height: 50px;
    width: 100%;
  }
  .categories-numbers-absolute {
    right: 0;
    top: 0;

    div {
      margin-right: 5px;
      width: 16px;
      height: 16px;
      background: rgba(246, 132, 36, 0.26);
      color: #f68424;
    }

    p {
      text-align: center;
      font-size: 11px;
      line-height: 16px !important;
    }
  }
  .dashboardRow,
  .dashboardCategories {
    background-color: #161619 !important;
  }
  .dashboard-categories-item {
    min-width: 60px;
  }
  .dashboardCategories {
    margin-right: 0 !important;
    border-radius: unset;

    .categories-active {
      background: linear-gradient(
                      180deg,
                      rgba(246, 132, 36, 0),
                      rgba(246, 132, 36, 0.25)
      );
      border-radius: unset;
      border-bottom: 2.29px solid $bg-orange;
    }
  }
}
