
.custom-dropdown{
      min-width: 110px;
}
.dropdown-option{
    display: flex !important;
    justify-content: space-between;
    img{
        width: 20px;
        height: 20px;
    }
}
