.address-tab-items {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.address-tab-item {
  /* background-color: #f68424; */
  background-color: #fff;
  padding: 25px 35px;
  /* color: white; */
  color: #9da4b3;
  border-radius: 14px;
  cursor: pointer;
}

.address-tab-item-title{
  display: block;
}

.address-tab-item-flag{
  display: none;
}

.address-tab-item-active {
  background-color: #f68424 !important;
  color: white !important;
}

.flights-main-top{
  margin-top: 50px;
}

@media (max-width: 700px) {
  .address-tab-item-title{
    display: none;
  }

  .address-tab-item-flag{
    display: block;
  }

  .address-tab-item{
    padding: 25px;
  }

  .flights-main-top{
    margin-top: 0;
  }
}

@media (max-width: 1200px) {
  .address-tab-items {
    margin-top: 20px;
  }
  
}
