
*,
*::after,
*::before {
  box-sizing: border-box;
}

.custom-radio-btn {
  --size: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;
  position: relative;
  padding-left: var(--size);
}

.custom-radio-btn input {
  display: none;
}

.custom-radio-btn.left {
  padding-left: 0;
  padding-right: var(--size);
}

.custom-radio-btn .label {
  margin-left: 5px;
}

.custom-radio-btn.left .label {
  margin: 0 5px;
}

.custom-radio-btn[aria-label]::after {
  content: attr(aria-label);
  display: inline-block;
  margin-left: 5px;
}

.custom-radio-btn .checkmark {
  --gap: 3px;
  --check-color: #f68424;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: inline-block;
  transition: opacity 0.3s ease;
  background: #EBE8E6;
  position: absolute;
  top: 0;
  left: 0;
}

.checkmark-checked {
  svg {
    transition: all 0.3s ease-in-out;
    transform: scale(0);
    height: 0;
  }
}

.checkmark-checked-true {
  background: #F68424 !important;
  transition: ease-in-out all 0.3s;

  svg {
    height: 100% !important;
    width: 8px !important;
    //margin-top: 5px;
  }

  path {
    fill: white;
  }
}

.checkmark-disabled-true {
  background: #ccc !important;
  transition: ease-in-out all 0.3s;

  svg {
    height: 100% !important;
    width: 8px !important;
    //margin-top: 5px;
  }

  path {
    fill: white
  }
}

.checkmark-checked.true {
  svg {
    height: 8px;
    transform: scale(1);
  }
}

.custom-radio-btn.left .checkmark {
  left: auto;
  right: 0;
}

.checkbox-div {
  height: 100%;
}

.custom-radio-btn input:checked ~ .checkmark {
  background-origin: content-box;
  background-repeat: no-repeat;
  background-image: radial-gradient(
                  circle at center,
                  var(--check-color) calc((var(--size) - (var(--border) * 2) - (var(--gap) * 2)) / 2),
                  rgba(255, 255, 255, 0) 0
  );
}
