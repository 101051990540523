.u2g-chat {
  position: fixed;
  bottom: 30px;
  right: 40px;
  z-index: 10;
}

.u2g-chat-button {
  background-color: #f68220;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out 0.3s;
  cursor: pointer;

  -webkit-box-shadow: 0 2px 5px 1px rgba(107, 104, 104, 0.5);
  box-shadow: 0 2px 5px 1px rgba(107, 104, 104, 0.5);
  -moz-box-shadow: 0 2px 5px 1px rgba(107, 104, 104, 0.5);
}

.u2g-chat-button:hover {
  background-color: #db7620;
}

.u2g-chat-button .close-icon {
  width: 20px;
  height: 20px;
}

.u2g-chat-button-icon {
  width: 30px;
  height: 30px;
}

@keyframes u2g-fade-in {
  0% {
    opacity: 0;
    bottom: -1000px;
  }
  100% {
    opacity: 1;
    bottom: 100px;
  }
}

@keyframes u2g-fade-out {
  0% {
    opacity: 1;
    bottom: 100px;
  }
  100% {
    opacity: 0;
    bottom: -1000px;
  }
}

.u2g-chat-window {
  position: fixed;
  bottom: -1000px;
  right: 40px;
  opacity: 0;
  background-color: #fff;
  border-radius: 8px;

  -webkit-box-shadow: 2px 3px 10px 2px rgba(139, 133, 133, 0.5);
  box-shadow: 2px 3px 10px 2px rgba(139, 133, 133, 0.5);
  -moz-box-shadow: 2px 3px 10px 2px rgba(139, 133, 133, 0.5);

  /* -webkit-animation: u2g-fade-out 0.3s ease-in-out alternate;
  -moz-animation: u2g-fade-out 0.3s ease-in-out alternate;
  animation: u2g-fade-out 0.3s ease-in-out alternate; */
}

.u2g-chat-window.u2g-show {
  bottom: 100px;
  right: 40px;
  opacity: 1;

  -webkit-animation: u2g-fade-in 0.3s ease-in-out alternate;
  -moz-animation: u2g-fade-in 0.3s ease-in-out alternate;
  animation: u2g-fade-in 0.3s ease-in-out alternate;
}

.u2g-chat-window-header-close {
  display: none;
}

@media (max-width: 768px) {
  .u2g-chat-window.u2g-show {
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    opacity: 1;
    display: flex;
    flex-direction: column;

    -webkit-animation: u2g-fade-in 0.3s ease-in-out alternate;
    -moz-animation: u2g-fade-in 0.3s ease-in-out alternate;
    animation: u2g-fade-in 0.3s ease-in-out alternate;
  }

  .u2g-chat-window-header-close {
    display: block;
  }

  .u2g-chat-window-body {
    flex: 1;
    height: auto !important;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .u2g-chat-window-header {
    border-radius: 0px !important;
    height: 60px;
    justify-content: space-between;
  }

  .u2g-chat-window {
    border-radius: 0;
  }

  .u2g-chat-messages {
    flex: 1;
  }

  .u2g-chat-input {
    height: 60px !important;
    flex-shrink: 0;
  }
}

.u2g-chat-window.u2g-closed {
  -webkit-animation: u2g-fade-out 0.3s ease-in-out alternate;
  -moz-animation: u2g-fade-out 0.3s ease-in-out alternate;
  animation: u2g-fade-out 0.3s ease-in-out alternate;
}

.u2g-chat-window-header {
  font-size: 18px;
  width: 100%;
  color: white;
  background-color: #f68220;
  border-radius: 8px 8px 0 0;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.u2g-chat-window-body {
  height: 500px;
}

.u2g-chat-start {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.u2g-margin-auto {
  margin: 0 auto;
}

.u2g-chat-start-button {
  width: 250px !important;
  height: 50px !important;
}

.u2g-chat-messages {
  height: 500px;
  /* height: 100%; */
  min-width: 380px;
  overflow-y: scroll;
  padding: 10px;
}

.u2g-chat-input {
  height: 40px;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  border-top: 1px solid #ddd;
}

.u2g-chat-input-disabled {
  background-color: #eee;
}

.u2g-chat-input-disabled input {
  background-color: #eee;
}

.u2g-chat-input input {
  height: 39px;
  width: 100%;
  border: 0;
}

.ai-chat-upload-panel {
  position: absolute;
  bottom: 46px;
  right: 10px;
  display: flex;
  gap: 10px;
  border: 1px solid #ddd;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #fff;
}

.ai-chat-upload-file-item {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.02);
  transition: all 0.3s;
}

.ai-chat-upload-file-item:hover {
  display: flex;
  align-items: center;
  border: 1px solid #f68220;
  padding: 8px;
  border-radius: 8px;
  background-color: white;
}

.ai-message-upload-panel {
  display: flex;
  justify-content: right;
  gap: 10px;
  /* border: 1px solid #ddd; */
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #fff;
}
