@font-face {
  font-family: HelveticaBoldCaps;
  src: url('../fonts/bold_caps/HelveticaNeueLTGEO-75Bold.eot');
  src: url('../fonts/bold_caps/HelveticaNeueLTGEO-75Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/bold_caps/HelveticaNeueLTGEO-75Bold.woff2') format('woff2'),
    url('../fonts/bold_caps/HelveticaNeueLTGEO-75Bold.woff') format('woff'),
    url('../fonts/bold_caps/HelveticaNeueLTGEO-75Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: HelveticaBold;
  // src: url('../fonts/HelveticaNeueLTGEOW82-75Bold/HelveticaNeueLTGEOW82-75Bold.eot');
  // src: url('../fonts/HelveticaNeueLTGEOW82-75Bold/HelveticaNeueLTGEOW82-75Bold.eot')
  //     format('embedded-opentype'),
  //   url('../fonts/HelveticaNeueLTGEOW82-75Bold/HelveticaNeueLTGEOW82-75Bold.woff2')
  //     format('woff2'),
  //   url('../fonts/HelveticaNeueLTGEOW82-75Bold/HelveticaNeueLTGEOW82-75Bold.woff')
  //     format('woff'),
  //   url('../fonts/HelveticaNeueLTGEOW82-75Bold/HelveticaNeueLTGEOW82-75Bold.ttf')
  //     format('truetype');
  src: url('../fonts//HelveticaNeueLTGEOW82-75Bold//HelveticaNeueLTGEO-75Bold.woff');
  font-weight: bold;
}

@font-face {
  font-family: HelveticaNeueLTGEO-55Roman;
  src: url('../fonts/HelveticaNeueLTGEOW82-55Roman/HelveticaNeueLTGEO-55Roman.woff');
  font-weight: bold;
}
