.ticket-container {
  height: calc(100% - 68px);

  .ticket-item {
    min-height: 130px;
    margin-bottom: 16px;
    background: #ffffff;
    border: 1px solid #f2f1f0;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 24px;

    h4 {
      font-family: HelveticaBold !important;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: #3b414d;
    }

    p {
      color: #878e99;
      font-size: 14px;
      line-height: 18px;
    }

    .priority-status {
      display: flex;
      margin-right: 8px;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
      padding: 5px 11px;
      max-width: 100px;
      min-width: 80px;

      p {
        font-style: normal;
        text-align: center;
        font-weight: normal;
        font-size: 12px;
        color: black;
        padding-bottom: 1px;
      }

      div {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 4px;
      }
    }
  }

  .priority-1 {
    background: #eaf8ed;

    div {
      background: #47ba9c;
    }
  }

  .priority-2 {
    background: rgba(246, 130, 32, 0.08);

    div {
      background: #f3d464;
    }
  }

  .priority-3 {
    background: rgba(246, 130, 32, 0.08);

    div {
      background: #f68220;
    }
  }

  .priority-4 {
    background: #ffe6e6;

    div {
      background: #fa3f3f;
    }
  }

  .ticket-status {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    max-width: 110px;
    border: 1px solid #f7f6f5;
    box-sizing: border-box;
    border-radius: 32px;
    padding: 5px 12px;

    p {
      text-align: center;
      font-size: 12px !important;
    }

    svg {
      margin-right: 6px;
    }
  }

  .ticket-details {
    box-sizing: border-box;
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 16px;

    .ticket-child-div {
      height: 50px;
      margin-bottom: 22px;
    }
  }

  .ticket-me {
    border: 1px solid #f2f1f0;

    .ticket-child-div {
      border-bottom: 1px solid #f2f1f0;
    }
  }

  .ticket-usa {
    background: #f7f6f5;

    div {
      border-bottom: 1px solid #e0dedd;
    }
  }

  .ticket-input {
    height: 200px !important;
  }

  .new-ticket-priority {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    border: 2px solid #f7f6f5;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 11px;
    margin-right: 8px;
    margin-top: 11px;

    p {
      text-align: center;
      margin-right: 10px;
    }
  }

  .new-ticket-priority-true {
    background: rgba(246, 130, 32, 0.08);
    border: 2px solid #f68220;
  }

  .ticket-file {
    background: #f7f6f5;
    border-radius: 12px;
    height: 64px;
    margin-top: 25px;
    padding: 24px 16px;
  }

  .reply-ticket {
    margin-top: 36px;
  }

  .ticket-delete-item {
    svg {
      width: 15px;
      height: 15px;
    }

    path {
      fill: #f68424;
    }
  }

  .ticket-imgs {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
}
