.u2g-message {
  margin: 10px 5px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.u2g-message-action-button {
  padding: 7px 15px;
  border: 1px solid #f68220;
  border-radius: 12px;
  color: #f68220;
  cursor: pointer;
  font-size: 13px;
  user-select: none;
  display: flex;
  align-items: center;
  transition: all 0.3s;
}

.u2g-message-action-button:hover {
  background-color: #f68220;
  border: 1px solid #f68220;
  color: #fff;
}

.u2g-message-message-body {
  overflow-wrap: break-word;
}

.u2g-message-message-body strong {
  font-family: HelveticaBoldCaps;
}

.u2g-message-message-body b {
  font-family: HelveticaBoldCaps;
}

.u2g-message-message-body p {
  padding: 5px 0;
}

.u2g-message-action-button-external-link {
  width: 13px;
  height: 13px;
  fill: #f68220;
}

.u2g-message-action-button:hover .u2g-message-action-button-external-link {
  fill: #fff;
}
