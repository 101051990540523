@import './Mixins.scss';
@import './Variables.scss';

.HawbBranch {
  height: 100%;

  .sidebar-child-scroll {
    padding-right: 60px;
    height: 90%;
  }

  .changableInfo {
    margin-bottom: 6px;
    cursor: pointer;
  }
}

.MapPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.349);
  width: 100%;
  height: 100%;

  .mapDiv {
    // width: 750px;
    width: 95%;
    height: 85vh;
    background-color: $color-white;
    border-radius: $primary-border-radius;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    .myMap {
      width: 100%;
      height: 48%;
      min-height: 250px;
      position: relative;
      border-radius: $primary-border-radius $primary-border-radius 0 0;
      overflow: hidden;
      // margin-bottom: 7px;
    }

    .CloseIcon {
      position: absolute;
      top: 24px;
      // left: 24px;
      right: 24px;
      width: 40px;
      height: 40px;
      z-index: 99;
      background-color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;

      .closeMap {
        width: 14px;
        height: 14px;

        path {
          stroke: $third-grey;
        }
      }
    }


    .buttonDivAdditional {
      margin-top: 20px;
      width: 100%;

      button {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        color: 'white';
      }

    }

    .addressLabel {
      // margin: 21px 29px 0;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 19px;
      color: $secondary-grey;
    }

    .inputDivAdditional {
      margin-top: 16px;
      border: 0.5px solid #dddddd;
      border-radius: $primary-border-radius ;
      padding: 22px 27px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 340px;
      input {
        height: 18px;
        width: 100%;
        border: none;
        color: $secondary-grey;
        font-weight: 800;
        font-size: 15px;
        line-height: 18px;
        padding: unset;
      }
    }

    .inputDiv {
      margin-top: 16px;
      border: 0.5px solid #dddddd;
      border-radius: $primary-border-radius $primary-border-radius 0 0;
      padding: 22px 27px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 400px;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 13px;

        path {
          fill: #000000;
        }
      }

      input {
        height: 18px;
        width: 100%;
        border: none;
        color: $secondary-grey;
        font-weight: 800;
        font-size: 15px;
        line-height: 18px;
        padding: unset;
      }

      input:disabled {
        background-color: unset;
      }

      input::placeholder {
        font-style: normal;
        font-weight: 800;
        font-size: 15px;
        line-height: 18px;
        color: $text-grey;
      }
    }

    .defaultAddress {
      .inputDiv {
        background-color: $primary-bg-color;
      }
    }

    .searchDiv {
      position: absolute;
      top: 24px;
      // left: 50%;
      left: 24px;
      //   transform: translate(-50%, 0);
      z-index: 99;
      background-color: $color-white;
      margin: 0;
      padding: 12px 27px;

      input::placeholder {
        color: $secondary-grey;
      }

      .css-2b097c-container {
        width: 100%;
        font-weight: 800;
        font-size: 15px;
        line-height: 18px;
      }

      .css-yk16xz-control:hover {
        border-color: unset;
      }

      .css-yk16xz-control {
        border-color: unset;
        box-shadow: unset;
        border-width: 0;
      }

      .css-1wy0on6 {
        display: none;
      }
    }

    .buttonDiv {
      margin: 21px 29px 32px;
      margin-top: auto;
      width: 400px;

      button {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
      }

      button:nth-child(1) {
        margin-right: 12px;
        color: $color-warning;
      }
    }
  }
}

@media (max-width: 899px) {
  
  .MapPopup .mapDiv {
    width: 100%;
    height: 100%;
    border-radius: 0 0 0 0;
  }

  .MapPopup .mapDiv .myMap {
    border-radius: 0 0 0 0;
  }
}

.additional-info {
  position: absolute;
  top: 67px;
  width: 400px;
  border: 0.5px solid #dddddd;
  border-top: 0;
  // left: 50%;
  left: 24px;
  //   transform: translate(-50%, 0);
  z-index: 40;
  background-color: $color-white;
  margin: 0;
  padding: 12px 27px;
}

@media (max-width: 600px) {

  .inputDivAdditional {
    width: 244px !important;
  }

  .additional-info {
    position: fixed !important;
    bottom: 0 !important;
    top: auto !important;
    width: 300px;
    left: 10px;
    z-index: 99;
    background-color: $color-white;
    margin: 0;
    padding: 12px 27px;
    border-radius: $primary-border-radius;
  }

  .MapPopup .mapDiv .addressLabel {
    width: 90%;
  }

  .MapPopup .mapDiv .inputDiv {
    width: 100%;
  }

  .MapPopup .mapDiv .buttonDiv {
    width: 90%;
  }

  .MapPopup .mapDiv .searchDiv {
    width: 80%;
    top: 10px;
    left: 10px;
    // top: 8px;
    // left: 5%;
    transform: unset;
    border-radius: $primary-border-radius;
  }
}

.eachPackage {
  background-color: $color-white;
  border-radius: $primary-border-radius;
  padding: 20px 16px;
  margin-top: 16px;
  font-size: 14px;
  flex-wrap: nowrap;

  .shopLogoDiv {
    overflow: hidden;
    height: 47px;
    width: 47px;
    margin-right: 16.5px;
    min-width: 47px;

    .shopLogo {
      height: 47px;
      width: 47px;
      object-fit: contain;
    }

    .declareImg,
    .notFoundImage {
      width: 25px;
      height: 25px;

      path {
        fill: $third-grey;
      }
    }

    .declareImg path {
      fill: $bg-danger;
    }
  }

  .declare {
    background-color: $bg-pink;
  }

  .declared {
    background-color: $primary-bg-color;
    border: 1px solid $border-grey;
  }

  .packageNumberDiv {
    .costInfo {
      color: $secondary-grey;
    }

    .packageNumber {
      color: $secondary-grey;
    }
  }

  .button-danger,
  .button-secondary {
    width: 160px;

    p {
      font-family: HelveticaBoldCaps;
    }
  }
}

.aliasTracking {
  color: $third-grey !important;
  font-size: 11px !important;
}

.eachPackage:hover .button-secondary {
  background-color: $color-white;
}

.clientComment {
  margin: 4px 10px 0 80px;
  color: $third-grey;
  font-size: 14px;
  font-weight: 400;
  word-break: break-all;
}

.eachPackage:hover {
  background-color: $primary-bg-color;

  .iconDiv {
    background-color: $color-white;
  }

  .toBeActivatedService {
    background-color: #fa3f3f;

    svg {
      path {
        fill: $color-white;
      }
    }
  }

  .declared {
    background-color: $color-white;
  }
}

.PackageDetailsDiv {
  height: 100%;
  padding: 0;

  .sidebarTitle {
    padding-right: 60px;
  }

  .sidebar-child-scroll {
    height: calc(100% - 64px);
    padding-right: 60px;
  }

  .PackageDetailsImageDiv {
    width: 100%;
    margin-bottom: 16px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      object-position: top;
      border-radius: $primary-border-radius;
    }

    .PackageImageHoverDiv {
      background-color: transparent;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: $primary-border-radius;
      transition: $primary-transition;

      svg {
        width: 40px;
        height: 35px;
        opacity: 0;
        transition: $primary-transition;

        path {
          fill: unset;
          stroke: $color-white;
          stroke-width: 1;
        }
      }
    }
  }

  .PackageDetailsImageDiv2 {
    width: 100%;
    margin-bottom: 16px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      object-position: top;
      border-radius: 0 0 $primary-border-radius $primary-border-radius;
    }

    .PackageImageHoverDiv {
      background-color: transparent;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: $primary-border-radius;
      transition: $primary-transition;

      svg {
        width: 40px;
        height: 35px;
        opacity: 0;
        transition: $primary-transition;

        path {
          fill: unset;
          stroke: $color-white;
          stroke-width: 1;
        }
      }
    }
  }

  .PackageDetailsImageDiv:hover {
    .PackageImageHoverDiv {
      background-color: #282c3380;

      svg {
        opacity: 1;
        transform: scale(1.2);
      }
    }
  }

  .PackageDetailsImageDiv2:hover {
    .PackageImageHoverDiv {
      background-color: #282c3380;

      svg {
        opacity: 1;
        transform: scale(1.2);
      }
    }
  }

  .ImagePreview {
    position: fixed;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(38, 42, 48, 0.6);

    .imageDiv {
      max-width: 640px;
      max-height: 480px;
      margin: 0 auto;
      overflow: hidden;
      margin-top: 5%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    .iconsDiv {
      position: relative;
      margin-top: 32px;

      .zoom,
      .rotate {
        margin-right: 32px;
        background-color: rgba(0, 0, 0, 0.239);
        width: 96px;
        height: 48px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-radius: $btn-border-radius;

        span {
          width: 1px;
          height: 100%;
          background-color: #0000000a;
        }

        svg {
          width: 18px;
          height: 18px;

          path {
            fill: $color-white;
          }
        }

        svg:nth-child(1) {
          border-right: 1px solid rgba(0, 0, 0, 0.04);
        }
      }

      .rotate {
        margin-right: unset;
      }

      svg path {
        fill: $color-white;
      }
    }

    .closeImagePreview {
      position: absolute;
      right: 5%;
      top: 50%;
      transform: translate(0, -50%);
      height: 48px;
      width: 48px;
      background-color: #0000003d;
      border-radius: 50%;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  .productsContainer {
    background-color: $primary-bg-color;
    border-radius: $primary-border-radius;
  }

  .clientCommentDiv {
    border-bottom: 1px solid #e0dedd;

    .eachCommentDiv {
      margin: 12px 0 8px;
      position: relative;

      .CommentInput {
        width: 75%;
        border: none;
        resize: none;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #383a3d;
        cursor: not-allowed;
        opacity: 0.5;
        transition: $primary-transition;
      }

      .activeCommentInput {
        cursor: text;
        opacity: 1;
        height: 65px;
      }

      .clientCommentBtns {
        position: absolute;
        top: 0;
        right: 1%;
        height: 38px;
        position: absolute;
        cursor: pointer;
        transition: transform 0.3s, background-color 0.3s, opacity 0.3s,
          visibility 0.3s;

        button {
          padding: unset;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            path {
              fill: $color-white;
            }
          }
        }

        button:nth-child(1) {
          margin-right: 5px;

          svg {
            path {
              stroke: $gray-color;
            }
          }
        }
      }

      .visible {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
      }

      .hidden {
        background-color: transparent;
        right: 0;
        opacity: 0;
        visibility: hidden;
        transform: scale(0);
      }
    }

    .clientComment {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #383a3d;
      max-width: 70%;
    }

    .iconDiv {
      width: 20px;
      height: 20px;
    }

    svg path {
      fill: $third-grey;
    }
  }

  .clientCommentTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: $third-grey;
  }

  .packageDetailsTitle {
    font-family: HelveticaBoldCaps;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    font-feature-settings: 'case' on;
    color: $secondary-grey;
  }

  .packageDeleteBtn {
    color: $color-white;
    padding: 13px 18px;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      margin-left: 4px !important;
    }

    svg {
      width: 14px;
      height: 14px;

      path {
        fill: $color-white;
      }
    }
  }

  svg {
    path {
      fill: $secondary-grey;
    }
  }

  .security {
    path {
      fill: $secondary-grey !important;
    }
  }

  .packageProductDivTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: $secondary-grey;
    margin-bottom: unset;
  }

  .packagePrice {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $third-grey;
    margin-right: 23px;
  }

  .packageTotalPrice {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: $secondary-grey;
    background-color: $primary-bg-color;
    border-radius: $primary-border-radius;
    padding: 12px 24px;
  }

  .BigTotalPrice {
    .packagePrice {
      margin-right: 6px;
    }

    .packageTotalPrice {
      padding: 12px 14px;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .addNewProduct {
    background: $primary-bg-color;
    border-radius: $primary-border-radius;
    padding: 16px;
    position: relative;
    cursor: pointer;
    transition: $primary-transition;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $third-grey;

    .iconDiv {
      background-color: $color-white;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      svg path {
        fill: $bg-orange;
      }
    }

    .uploadInput {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }

  .addNewProduct:hover {
    background-color: $bg-grey-hover;
  }

  .insurance {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $secondary-grey;
  }
}

.sent .hawbComment {
  svg path {
    @include sent;
  }

  @include sent;
}

.arrived .hawbComment {
  svg path {
    @include arrived;
  }

  @include arrived;
}

.returned .hawbComment {
  svg path {
    @include returned;
  }

  @include returned;
}

.hawbComment {
  padding: 25px 17px 25px 16px;
  border-radius: $primary-border-radius;

  svg {
    width: 20px;
    height: 20px;
  }

  p {
    font-size: 14px;
  }
}

@media (max-width: 1350px) {
  .packagePrice {
    margin-right: 18px !important;
  }

  .packageTotalPrice {
    padding: 12px !important;
  }
}

@media (max-width: 1200px) {
  .PackageDetailsDiv {
    .sidebarTitle {
      padding: 0 30px;
    }

    .sidebar-child-scroll {
      padding: 0 30px;
      height: calc(100% - 42px);
    }

    .CommentInput {
      width: 85% !important;
    }
  }
}

@media (max-width: 900px) {
  .eachPackageWrapper {
    padding: 2px 0;
  }

  .eachPackage:hover {
    background-color: $color-white;
  }

  .eachPackage {
    padding: unset;
  }

  .clientComment {
    margin: 4px 10px 0 63.5px;
  }

  .CommentInput {
    max-width: 80%;
  }
}

@media (max-width: 768px) {
  .PackageDetailsDiv {
    .sidebarTitle {
      padding: 0 20px;
    }
  }

  .CommentInput {
    max-width: 75%;
  }
}

@media (max-width: 500px) {
  .packageNumber {
    overflow: hidden;
    word-break: break-word;
  }

  .CommentInput {
    max-width: 65%;
  }
}

.imageTab {
  border: 1px solid #eee;
  border-bottom: 0;
  padding: 10px 20px;
  width: 50%;
  border-radius: 8px 0 0 0;
  text-align: center;
  cursor: pointer;
  font-family: HelveticaBoldCaps;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  font-feature-settings: 'case' on;
  color: #3b414d;
}

.rightTab {
  border-radius: 0 8px 0 0 !important;
  border-left: 0;
}

.activeImageTab {
  background-color: #f68424;
  border-color: #f68424;
  color: white;
  cursor: default !important;
}

.declare-summary{
  flex-wrap: wrap;
}
