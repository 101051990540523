@import '../../scss/Variables';

.overflow-y {
  overflow-y: hidden;
}

.overlay {
  position: fixed;
  // visibility: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 10;
  -webkit-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.sidebar {
  background-color: white;
  position: absolute;
  padding: 60px;
  padding-bottom: 30px;
  padding-right: 0;
  right: 0;
  margin-right: -100%;
  visibility: hidden;
  opacity: 0;
  width: 25%;
  min-width: 500px;
  height: 100vh;
  top: 0;
  -webkit-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;

  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  animation: Sidebar 500ms ease-in-out;
}
.sidebar-true {
  margin-right: 0;
  visibility: visible;
  z-index: 10;
  opacity: 1;
}
@keyframes Sidebar {
  from {
    margin-right: -100%;
    visibility: hidden;
    z-index: 0;
    opacity: 0;
  }
  to {
    margin-right: 0;
    visibility: visible;
    z-index: 10;
    opacity: 1;
  }
}

.SidebarChildDiv {
  height: 100%;
}

.empty-area {
  max-width: 100%;
  opacity: 0;
  visibility: hidden;
  min-height: 100vh;
  background: rgba(28, 31, 36, 0.84);
  -webkit-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  animation: Overlay 500ms ease-in-out;
}
@keyframes Overlay {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}

.sidebar,
.empty-area:hover {
  cursor: auto;
}

.empty-area-true {
  visibility: visible;
  opacity: 1;
}

.close-modal-btn {
  background: $bg-orange;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  margin-left: -25px;
  margin-top: 45px;
  left: 0;
}
.close-modal-btn {
  svg path {
    fill: $color-white !important;
  }
  .closeIcn path {
    stroke: $color-white;
  }
  div {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

close-modal-btn {
  background: $bg-orange;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  margin-left: -25px;
  margin-top: 45px;
  left: 0;
}

.form {
  height: calc(100% - 68px);
  .sidebarButtonDiv button {
    margin-top: 24px;
  }
}

.registration {
  height: calc(100% - 28px);
  .swiper-slide {
    height: 100% !important;
  }
  .sidebarButtonDiv button:first-child {
    margin-top: 24px;
  }
}

@media (max-width: 1350px) {
  .pr-60 {
    padding-right: unset !important;
  }
  .sidebar {
    padding-left: 50px;
  }
  .sidebarTitle {
    padding-right: 50px !important;
  }
  .sidebar-child-scroll {
    padding-right: 50px !important;
  }
  .sidebarButtonDiv {
    padding: 0 50px 0 30px !important;
  }
  .form .sidebarButtonDiv {
    padding: 0 50px 0 0 !important;
  }
  .registration-first-slide {
    padding-right: 60px !important;
  }
}

// .sidebarButtonDiv {
//   //padding: 0 30px !important;
//   padding-bottom: 30px !important;
// }

@media (max-width: 1199px) {
  .sidebar {
    height: 100%;

    .sidebarTitle {
      .button {
        max-width: 100px;
      }
    }
  }
  // .SidebarChildDiv {
  //   max-height: 90%;
  // }
  .sideBarChildComponent {
    height: 100%;

    .sidebarTitle {
      margin-bottom: unset !important;
      padding-bottom: 14px !important;
      max-height: 20%;
    }
  }
  // .sidebarButtonDiv {
  //   padding: 0 30px !important;
  //   padding-bottom: 60px !important;
  // }
}

@media (max-width: 768px) {
  .sidebar {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0;
  }
  .sidebar-child-scroll {
    padding: 0 20px !important;
  }
  .sidebarButtonDiv, .form .sidebarButtonDiv, .registration-first-slide {
    padding: 0 20px !important;
  }

  .close-modal-btn {
    margin-left: 15px;
    top: -30px;
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 500px) {
  .sidebarTitle {
    padding: 0 10px !important;
  }
  .sidebar-child-scroll {
    padding: 0 10px !important;
  }
  .sidebarButtonDiv, .form .sidebarButtonDiv, .registration-first-slide {
    padding: 0 10px !important;
  }
  .sidebar {
    min-width: unset;
  }
}
