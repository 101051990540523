.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 100;
  visibility: hidden;
  transition: all 500ms ease-in-out;
  background: transparent;
}

.opacity {
  opacity: 0;
  width: 100%;
  height: 100%;
  background: #262a30;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 500ms ease-in-out;
}

.opacity-true {
  opacity: 0.6;
}

.modal-bg {
  padding: 50px;
  max-width: 550px;
  width: 100%;
  height: auto;
  border-radius: 12px;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: white;
  transition: all 500ms ease-in-out;
}

.modal-bg-true {
  visibility: visible;
  opacity: 1;
}

.modal-container-true {
  visibility: visible;
}

.modal-single-button {
  width: 158px;
}