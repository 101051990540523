.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 16px;
}
.toggle-switch input[type='checkbox'] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #f5f4f3;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: '';
  left: 0;
  top: -1.5px;
  width: 20px;
  height: 20px;
  background-color: #878e99;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type='checkbox']:checked + .switch::before {
  transform: translateX(25px);
  background-color: #f68424;
}
.toggle-switch input[type='checkbox']:checked + .switch {
  background-color: #f5f4f3;
}
