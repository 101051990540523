@import '../../scss/Variables';

.checkbox {
  display: none;
}
.checkbox + span {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background: white left top no-repeat;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 50%;
}
.checkbox:checked + span {
  background: $bg-orange;
  transition: $primary-transition;
  border: none;
}

.checkbox + span {
  margin-right: 4px;
}

.checkbox-checked {
  margin-bottom: 10px;
  position: absolute;
  top: -2px;
  left: 5px;
  svg {
    transition: $primary-transition;
    transform: scale(0);
    height: 0;
  }
}

.checkbox-checked.true {
  svg {
    height: 8px;
    transform: scale(1);
  }
}
