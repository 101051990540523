@import '../../scss/Variables';

.ci-float-label {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.ci-float-label input,
.ci-float-label textarea {
  padding: 10px;
  outline: 0;
  width: 100%;
  border-radius: 12px;
  height: 50px;
  margin-bottom: 22px;
  border: 1px solid #ddd;
  background: transparent;
  font-size: 18px;
}

.ci-float-label label {
  font-size: 12px;
  padding-left: 7px;
  margin-bottom: 4px;
}

// .ci-float-label .inputWithRightIcon {
//   padding-right: 45px;
// }

// .ci-float-label label {
//   font-size: 14px;
//   padding: 0 15px;
//   color: #999;
//   pointer-events: none;
//   position: absolute;
//   top: 45%;
//   transform: translate(0, -100%) scale(0.75);
//   transform-origin: top left;
//   transition: all 0.2s ease-out;
// }

// .ci-validation-error-text {
//   margin-top: -25px;
//   max-height: 14px;
//   margin-bottom: 20px;
// }

// .ci-float-label:focus-within label {
//   transform: translate(0, -130%) scale(0.75);
//   padding: 0 15px;
//   //top: 45%;
//   //margin-left: 3px;
// }

// .ci-float-label .Active {
//   transform: translate(0, -130%) scale(0.75);
//   padding: 0 15px;
// }

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}