@import 'Variables';

.user-type-div {
  background: #f7f6f5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 16px 24px;
  margin-bottom: 16px;
}

.form-container {
  border-radius: 12px;
}

.registr-user-type-text {
  opacity: 0.6;
}

@media (max-width: 1199px) {
  .form-container {
    padding: 0;
  }
}
