@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.loader-container {
  position: fixed;
  background: #262a30;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 100;
  visibility: hidden;
  transition: opacity 500ms ease-in-out, visibility 500ms ease-in-out;
}

.loader-bg {
  width: 60px;
  height: 60px;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  transition: opacity 500ms ease-in-out, visibility 500ms ease-in-out;
  overflow: hidden;
}

.loader {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  animation: spin 0.5s linear infinite;
  z-index: 20;
  transition: all 500ms ease-in-out;

  svg {
    width: 60px;
    height: 60px;
  }
}

.loader-bg-true {
  visibility: visible;
  opacity: 1;
}

.loader-container-true {
  opacity: 0.6;
  visibility: visible;
}
